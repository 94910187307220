/* eslint-disable react-hooks/rules-of-hooks */

import { Breadcrumb, Button } from 'react-bootstrap';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { ChevronRight, Edit, TrashCan } from '@carbon/icons-react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import React, { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from 'react';

import ApiHelper from '../../Shared/ApiHelper';
import DataTable from '../../Shared/components/DataTable';
import { HmisProject } from '../../Shared/SharedModels';
import ProjectDeleteModal from './ProjectEdit/ProjectDeleteModal';
import ProjectListFilters from './ProjectFilters';
import { UserProfileContext } from '../../Shared/contexts/UserProfile';
import { useMsal } from '@azure/msal-react';

const ProjectList: FunctionComponent = () => {
    const navigate: NavigateFunction = useNavigate();
    const { instance } = useMsal();
    const api = useMemo(() => new ApiHelper(), []);

    const [isListLoading, setIsListLoading] = useState(false);
    const [projects, setProjects] = useState<HmisProject[]>([]);
    const [filteredProjects, setFilteredProjects] = useState<HmisProject[]>([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [projectToBeDeleted, setProjectToBeDeleted] = useState<HmisProject>();

    const fetchIdRef = useRef(0);

    useEffect(() => {
        const fetchId = ++fetchIdRef.current;

        setIsListLoading(true);
        
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/User/projects'
        )
            .then(async (result: Response) => {
                if (result && fetchId === fetchIdRef.current) {
                    const hmisProjects: HmisProject[] = await result.json();
                    setProjects(hmisProjects);
                }
            })
            .catch(error => {
                console.error('Failed to fetch projects', error);
            })
            .finally(() => {
                setIsListLoading(false);
            });
    }, [api, instance]);

    const projectColumns: ColumnDef<HmisProject>[] = useMemo(
        () => [
            { header: 'Project ID', accessorKey: 'projectId' },
            { header: 'Name', accessorKey: 'projectName' },
            {
                header: 'Type',
                accessorFn: (project: HmisProject) => project.projectType?.text,
                cell: ({ row }: CellContext<HmisProject, unknown>) => {
                    return row.original.projectType?.text;
                },
            },
            {
                header: 'Organization',
                accessorFn: (project: HmisProject) => project.organization?.organizationName,
                cell: ({ row }: CellContext<HmisProject, unknown>) => {
                    return row.original.organization?.organizationName;
                },
            },
            {
                header: 'Source System',
                accessorFn: (project: HmisProject) => project.sourceSystem?.sourceSystemShortName,
                cell: ({ row }: CellContext<HmisProject, unknown>) => {
                    return row.original.sourceSystem?.sourceSystemShortName;
                },
            },
            {
                header: 'Actions',
                cell: ({ row }: CellContext<HmisProject, unknown>) => {
                    const userProfile = useContext(UserProfileContext);
                    return (
                        <div className="flex-row">
                            {userProfile.environmentConfig.enableCustomUploads && (
                                <Button
                                    data-testid={row.original.projectId + "editButton"}
                                    variant="link"
                                    onClick={() => {
                                        navigate(`${row.original.organizationKey}/${row.original.projectKey}`);
                                    }}
                                >
                                    <Edit size={24} />
                                </Button>
                            )}
                            <Button
                                data-testid={row.original.projectId + "deleteButton"}
                                variant="link-danger"
                                onClick={() => {
                                    setShowDeleteModal(true);
                                    setProjectToBeDeleted(row.original);
                                }}
                            >
                                <TrashCan size={24} />
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [navigate]
    );

    return (
        <div className="content-column-container">
            <Breadcrumb>
                <Breadcrumb.Item
                    onClick={() => {
                        navigate('/admin');
                    }}
                >
                    Configuration
                </Breadcrumb.Item>
                <ChevronRight size={24} className="breadcrumb-chevron" />
                <Breadcrumb.Item active>Projects</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content-header">
                <h1 className="content-title">Projects</h1>
            </div>
            <ProjectListFilters masterProjectsList={projects} setFilteredProjectsList={setFilteredProjects} />
            <DataTable
                label="Source Systems"
                data={filteredProjects}
                columns={projectColumns}
                loading={isListLoading}
            />
            <ProjectDeleteModal
                setProjects={setProjects}
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                setActiveProject={setProjectToBeDeleted}
                activeProject={projectToBeDeleted}
            />
        </div>
    );
};

export default ProjectList;

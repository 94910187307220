import { CustomerProjectCsv, VCOProject } from './CustomProjectModels';
import { Form, Spinner } from 'react-bootstrap';
import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { Slide, toast } from 'react-toastify';

import ApiHelper from '../../../Shared/ApiHelper';
import DatePicker from 'react-datepicker';
import SaveButton from '../../../Shared/components/SaveButton';
import { UserProfileContext } from '../../../Shared/contexts/UserProfile';
import { useMsal } from '@azure/msal-react';

export interface ProjectEditFormProps {
    vcoProject: VCOProject;
    batchKey: number;
    isLoading: boolean;
}

const ProjectEditForm: FunctionComponent<ProjectEditFormProps> = (props: ProjectEditFormProps) => {
    const { vcoProject, batchKey, isLoading } = props;
    const userProfile = useContext(UserProfileContext);

    const [customProjectCsv, setCustomProjectCsv] = useState<CustomerProjectCsv>({ batchKey: 0 });
    const [isSaving, setIsSaving] = useState(false);

    const api = useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();

    useEffect(() => {
        const customProject: CustomerProjectCsv = {
            batchKey: batchKey,
            sourceSystemShortName:
                userProfile.sourceSystems.find((s) => s.sourceSystemKey === vcoProject.sourceSystemKey)
                    ?.sourceSystemShortName ?? '',
            projectID: vcoProject.projectID,
            organizationID: vcoProject.organizationID,
            projectName: vcoProject.projectName,
            projectCommonName: vcoProject.projectCommonName,
            continuumProject: vcoProject.continuumProjectKey,
            projectType: vcoProject.projectTypeKey,
            housingType: vcoProject.housingTypeKey,
            rrhSubType: vcoProject.rrhSubTypeKey,
            residentialAffiliation: vcoProject.residentialAffiliationKey,
            targetPopulation: vcoProject.targetPopulationKey,
            hopwaMedAssistedLivingFac: vcoProject.hopwaMedAssistedLivingFacKey,
            userID: vcoProject.userID,
            operatingStartDate: vcoProject.operatingStartDate,
            operatingEndDate: vcoProject.operatingEndDate,
            pitCount: vcoProject.pitCount,
            dateCreated: vcoProject.dateCreated,
            dateUpdated: vcoProject.dateUpdated,
            dateDeleted: vcoProject.dateDeleted,
        };
        setCustomProjectCsv(customProject);
    }, [
        batchKey,
        userProfile.sourceSystems,
        vcoProject.continuumProjectKey,
        vcoProject.dateCreated,
        vcoProject.dateDeleted,
        vcoProject.dateUpdated,
        vcoProject.hopwaMedAssistedLivingFacKey,
        vcoProject.housingTypeKey,
        vcoProject.operatingEndDate,
        vcoProject.operatingStartDate,
        vcoProject.organizationID,
        vcoProject.pitCount,
        vcoProject.projectCommonName,
        vcoProject.projectID,
        vcoProject.projectName,
        vcoProject.projectTypeKey,
        vcoProject.residentialAffiliationKey,
        vcoProject.rrhSubTypeKey,
        vcoProject.sourceSystemKey,
        vcoProject.targetPopulationKey,
        vcoProject.userID,
    ]);

    return (
        <div className="project-card mb-5">
            <div className="project-card-header">
                <h3>Project CSV Data</h3>
                {!!customProjectCsv.batchKey && (
                    <SaveButton
                        isSaving={isSaving}
                        onClick={() => {
                            setIsSaving(true);
                            api.callApi(
                                instance,
                                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                process.env.REACT_APP_NET_API_URL + `/CustomProject/projects`,
                                'POST',
                                JSON.stringify(customProjectCsv)
                            )
                                .then(async (_result: Response) => {
                                    toast.success('Project Data Saved Successfully', {
                                        position: 'top-right',
                                        autoClose: 5000,
                                        transition: Slide,
                                        draggable: false,
                                        closeOnClick: false,
                                        theme: 'colored',
                                    });
                                })
                                .finally(() => {
                                    setIsSaving(false);
                                });
                        }}
                    />
                )}
            </div>
            {isLoading ? (
                <Spinner
                    className="centered-spinner"
                    variant="primary"
                    size="sm"
                    animation="border"
                    data-testid="projectEditFormSpinner"
                />
            ) : (
                <div className="project-card-body">
                    <Form className="card-form">
                        <Form.Group className="wide-card-form-group">
                            <Form.Label>Project Name*</Form.Label>
                            <Form.Control
                                data-testid="projectNameInput"
                                placeholder="Enter Project Name"
                                isInvalid={!customProjectCsv.projectName}
                                value={customProjectCsv?.projectName}
                                onChange={(event) => {
                                    setCustomProjectCsv((prev) => {
                                        return { ...prev, projectName: event.target.value };
                                    });
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="card-form-group">
                            <Form.Label>Project Common Name</Form.Label>
                            <Form.Control
                                placeholder="Enter Project Common Name"
                                value={customProjectCsv?.projectCommonName}
                                onChange={(event) => {
                                    setCustomProjectCsv((prev) => {
                                        return { ...prev, projectCommonName: event.target.value };
                                    });
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="card-form-group">
                            <Form.Label>Operating Start Date*</Form.Label>
                            <DatePicker
                                placeholderText="Operating Start Date"
                                className="form-control"
                                dateFormat="MM-dd-yyyy"
                                selected={
                                    customProjectCsv.operatingStartDate
                                        ? new Date(customProjectCsv.operatingStartDate)
                                        : undefined
                                }
                                onChange={(date: Date) => {
                                    setCustomProjectCsv((prev) => {
                                        return {
                                            ...prev,
                                            operatingStartDate: date ? date.toISOString().split('T')[0] : undefined,
                                        };
                                    });
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="card-form-group">
                            <Form.Label>Operating End Date</Form.Label>
                            <DatePicker
                                placeholderText="Operating End Date"
                                className="form-control"
                                dateFormat="MM-dd-yyyy"
                                selected={
                                    customProjectCsv.operatingEndDate
                                        ? new Date(customProjectCsv.operatingEndDate)
                                        : undefined
                                }
                                onChange={(date: Date) => {
                                    setCustomProjectCsv((prev) => {
                                        return {
                                            ...prev,
                                            operatingEndDate: date ? date.toISOString().split('T')[0] : undefined,
                                        };
                                    });
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="card-form-group">
                            <Form.Label>Continuum Project*</Form.Label>
                            <Form.Control
                                placeholder="Enter Continuum Project"
                                isInvalid={customProjectCsv.continuumProject === undefined}
                                value={customProjectCsv?.continuumProject ?? ''}
                                onChange={(event) => {
                                    const value = parseInt(event.target.value);
                                    const isNumber = !isNaN(value);
                                    if (isNumber) {
                                        setCustomProjectCsv((prev) => {
                                            return { ...prev, continuumProject: value };
                                        });
                                    } else if (event.target.value === '') {
                                        setCustomProjectCsv((prev) => {
                                            return { ...prev, continuumProject: undefined };
                                        });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="card-form-group">
                            <Form.Label>Project Type</Form.Label>
                            <Form.Control
                                placeholder="Enter Project Type"
                                value={customProjectCsv?.projectType ?? ''}
                                onChange={(event) => {
                                    const value = parseInt(event.target.value);
                                    const isNumber = !isNaN(value);
                                    if (isNumber) {
                                        setCustomProjectCsv((prev) => {
                                            return { ...prev, projectType: value };
                                        });
                                    } else if (event.target.value === '') {
                                        setCustomProjectCsv((prev) => {
                                            return { ...prev, projectType: undefined };
                                        });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="card-form-group">
                            <Form.Label>Housing Type</Form.Label>
                            <Form.Control
                                placeholder="Enter Housing Type"
                                value={customProjectCsv?.housingType ?? ''}
                                onChange={(event) => {
                                    const value = parseInt(event.target.value);
                                    const isNumber = !isNaN(value);
                                    if (isNumber) {
                                        setCustomProjectCsv((prev) => {
                                            return { ...prev, housingType: value };
                                        });
                                    } else if (event.target.value === '') {
                                        setCustomProjectCsv((prev) => {
                                            return { ...prev, housingType: undefined };
                                        });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="card-form-group">
                            <Form.Label>RRH Sub Type</Form.Label>
                            <Form.Control
                                placeholder="Enter RRH Sub Type"
                                value={customProjectCsv?.rrhSubType ?? ''}
                                onChange={(event) => {
                                    const value = parseInt(event.target.value);
                                    const isNumber = !isNaN(value);
                                    if (isNumber) {
                                        setCustomProjectCsv((prev) => {
                                            return { ...prev, rrhSubType: value };
                                        });
                                    } else if (event.target.value === '') {
                                        setCustomProjectCsv((prev) => {
                                            return { ...prev, rrhSubType: undefined };
                                        });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="card-form-group">
                            <Form.Label>Residential Affiliation</Form.Label>
                            <Form.Control
                                placeholder="Enter Residential Affiliation"
                                value={customProjectCsv?.residentialAffiliation ?? ''}
                                onChange={(event) => {
                                    //console.log(event.target.value);
                                    const value = parseInt(event.target.value);
                                    const isNumber = !isNaN(value);
                                    if (isNumber) {
                                        setCustomProjectCsv((prev) => {
                                            return { ...prev, residentialAffiliation: value };
                                        });
                                    } else if (event.target.value === '') {
                                        setCustomProjectCsv((prev) => {
                                            return { ...prev, residentialAffiliation: undefined };
                                        });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="card-form-group">
                            <Form.Label>Target Population</Form.Label>
                            <Form.Control
                                placeholder="Enter Target Population"
                                value={customProjectCsv?.targetPopulation ?? ''}
                                onChange={(event) => {
                                    const value = parseInt(event.target.value);
                                    const isNumber = !isNaN(value);
                                    if (isNumber) {
                                        setCustomProjectCsv((prev) => {
                                            return { ...prev, targetPopulation: value };
                                        });
                                    } else if (event.target.value === '') {
                                        setCustomProjectCsv((prev) => {
                                            return { ...prev, targetPopulation: undefined };
                                        });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="card-form-group">
                            <Form.Label>HopwaMedAssistedLivingFac</Form.Label>
                            <Form.Control
                                placeholder="Enter HopwaMedAssistedLivingFac"
                                value={customProjectCsv?.hopwaMedAssistedLivingFac ?? ''}
                                onChange={(event) => {
                                    const value = parseInt(event.target.value);
                                    const isNumber = !isNaN(value);
                                    if (isNumber) {
                                        setCustomProjectCsv((prev) => {
                                            return { ...prev, hopwaMedAssistedLivingFac: value };
                                        });
                                    } else if (event.target.value === '') {
                                        setCustomProjectCsv((prev) => {
                                            return { ...prev, hopwaMedAssistedLivingFac: undefined };
                                        });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="card-form-group">
                            <Form.Label>PIT Count</Form.Label>
                            <Form.Control
                                placeholder="Enter PIT Count"
                                value={customProjectCsv?.pitCount ?? ''}
                                onChange={(event) => {
                                    const value = parseInt(event.target.value);
                                    const isNumber = !isNaN(value);
                                    if (isNumber) {
                                        setCustomProjectCsv((prev) => {
                                            return { ...prev, pitCount: value };
                                        });
                                    } else if (event.target.value === '') {
                                        setCustomProjectCsv((prev) => {
                                            return { ...prev, pitCount: undefined };
                                        });
                                    }
                                }}
                            />
                        </Form.Group>
                    </Form>
                </div>
            )}
        </div>
    );
};

export default ProjectEditForm;

export interface EmbededDashboardResponse {
    dashboardId: string;
    embedUrl: string;
    embedToken: EmbedToken;
}

export interface ReportMetadata {
    reportKey: string;
    workSpaceId: string;
    reportId: string;
    name: string;
    description?: string;
    createdBy?: string;
    ownedBy?: string;
    type?: string;
    categories?: ReportCategory[];
    stage?: ReportStage;
    isFavorited?: boolean;
    isSelectedByUser?: boolean;
    datasetGuid: string;
}

export interface ReportCategory {
    categoryKey: string;
    categoryName: string;
    createdBy: string;
    colorHex: string;
    dateCreated: string;
    dateUpdated: string;
    isCustom: boolean;
    isDeleted: boolean;
    updatedBy: string;
}

export interface EmbededReportResponse {
    type: string;
    embedReport: ReportEmbedDetails[];
    embedToken: EmbedToken;
    datasetId: string;
}

export interface ReportEmbedDetails {
    reportId: string;
    reportName: string;
    embedUrl: string;
}

export interface EmbedToken {
    token: string;
    tokenId: string;
    expiration: Date;
}

export interface SaveEventDetails {
    error?: string;
    originalReportObjectId: string;
    reportName: string;
    reportObjectId: string;
    saveAs: boolean;
}

export interface ReportCreationPayload {
    Name: string;
    ReportId: string;
    Description: string;
    Categories: CategoryKey[];
    Type: string;
    Dataset?: string;
}

export interface CategoryKey {
    CategoryKey: string;
}

export interface CategoryOption {
    label: string;
    value: string;
}

export interface Category {
    categoryKey: string;
    categoryName: string;
    colorHex: string;
    isDeleted: boolean;
    isCustom: boolean;
    createdBy?: string;
    updatedBy?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
}

export interface FavoriteReport {
    reportKey: string;
    name: string;
    workSpaceId: string;
    reportId: string;
    datasetGuid: string;
}

export enum ReportStage {
    Private = 0,
    Published = 1,
    'In Review' = 2,
}

export interface ReportEmbedConfigBuilder {
    type: string;
    datasetId: string | undefined;
    accessToken: string;
    embedUrl: string;
    createdByUser: boolean | undefined;
    editMode: boolean | undefined;
    reportId: string | undefined;
}

export interface ReportDataset {
    datasetKey: number;
    customerId: string;
    datasetGuid: string;
    typeName: string;
    isPublic: boolean;
}

export interface ActivityLogEntry {
    Activity: string;
    Dataset: string;
    DatasetId: string;
    Report: string;
    ReportId: string;
    ReportType: string;
    PageName: string;
    OriginalReportId: string;
    LoadDuration: number;
    RenderDuration: number;
    CorrelationId: string;
}

import { Organization, Project, SourceSystem } from '../Shared/SharedModels';

export interface User {
    dateCreated?: Date;
    dateUpdated?: Date;
    firstName?: string;
    lastName?: string;
    jobTitle?: string;
    organizations?: Organization[];
    projects?: Project[];
    role?: UserRole;
    roleKey?: number;
    sourceSystems?: SourceSystem[];
    status?: UserStatus;
    upn?: string;
    userKey?: string;
    defaultCustomerId?: string;
    customerId?: string;
}

export enum UserStatus {
    Pending = 0,
    Active = 1,
    Inactive = 2,
}

export interface UserRole {
    description: string;
    name: string;
    roleKey: number;
}

export interface DisplayUser {
    name?: string;
    role?: string;
    jobTitle?: string;
    status?: string;
    fullUser: User;
}

import { COLUMNS, DashboardLayout, DashboardVisual, SPAN_TYPE } from './DashboardModels';
import React, { FunctionComponent } from 'react';

import { ChevronDown } from '@carbon/icons-react';
import { Dropdown } from 'react-bootstrap';

interface LayoutDropdownProps {
    layout: DashboardLayout;
    setLayout: React.Dispatch<React.SetStateAction<DashboardLayout>>;
    savePreferences: (layout?: DashboardLayout, selectedVisuals?: DashboardVisual[]) => void;
}

const LayoutDropdown: FunctionComponent<LayoutDropdownProps> = (props: LayoutDropdownProps) => {
    const { layout, setLayout, savePreferences } = props;

    return (
        <Dropdown className="mx-2">
            <Dropdown.Toggle id="layout-dropdown-basic-button" variant="outline-primary">
                <img className="button-icon-padding" src={layout.icon} alt="Layout" height="24" />
                <ChevronDown size={20} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="layout-dropdown-menu">
                <Dropdown.Item
                    onClick={() => {
                        const newLayout = {
                            columns: COLUMNS.ONE,
                            span: SPAN_TYPE.NONE,
                            icon: 'DashboardLayouts/column1.svg',
                        };
                        setLayout(newLayout);
                        savePreferences(newLayout, undefined);
                    }}
                >
                    <img src="DashboardLayouts/column1.svg" alt="Column 1" height="24" />
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => {
                        const newLayout = {
                            columns: COLUMNS.TWO,
                            span: SPAN_TYPE.COLSPAN,
                            icon: 'DashboardLayouts/colspan.svg',
                        };
                        setLayout(newLayout);
                        savePreferences(newLayout, undefined);
                    }}
                >
                    <img src="DashboardLayouts/colspan.svg" alt="Colspan" height="24" />
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => {
                        const newLayout = {
                            columns: COLUMNS.TWO,
                            span: SPAN_TYPE.NONE,
                            icon: 'DashboardLayouts/column2.svg',
                        };
                        setLayout(newLayout);
                        savePreferences(newLayout, undefined);
                    }}
                >
                    <img src="DashboardLayouts/column2.svg" alt="Column 2" height="24" />
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => {
                        const newLayout = {
                            columns: COLUMNS.THREE,
                            span: SPAN_TYPE.NONE,
                            icon: 'DashboardLayouts/column3.svg',
                        };
                        setLayout(newLayout);
                        savePreferences(newLayout, undefined);
                    }}
                >
                    <img src="DashboardLayouts/column3.svg" alt="Column 2" height="24" />
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => {
                        const newLayout = {
                            columns: COLUMNS.TWO,
                            span: SPAN_TYPE.ROWSPAN,
                            icon: 'DashboardLayouts/rowspan.svg',
                        };
                        setLayout(newLayout);
                        savePreferences(newLayout, undefined);
                    }}
                >
                    <img src="DashboardLayouts/rowspan.svg" alt="Column 2" height="24" />
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default LayoutDropdown;

import './ProjectEditPage.css';

import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';

import ApiHelper from '../../../Shared/ApiHelper';
import { Breadcrumb } from 'react-bootstrap';
import { ChevronRight } from '@carbon/icons-react';
import InventoryEditForm from './InventoryEditForm';
import { Organization } from '../../../Shared/SharedModels';
import ProjectEditForm from './ProjectEditForm';
import { VCOProject } from './CustomProjectModels';
import { useMsal } from '@azure/msal-react';

const ProjectEditPage: FunctionComponent = () => {
    const navigate: NavigateFunction = useNavigate();
    const { projectKey, organizationKey } = useParams();

    const [vcoProject, setVcoProject] = useState<VCOProject>({ projectKey: 0 });
    const [organization, setOrganization] = useState<Organization>();
    const [batchKey, setBatchKey] = useState(0);
    const [isProjectLoading, setIsProjectLoading] = useState(false);

    const fetchIdRef = useRef(0);

    const api = useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();

    useEffect(() => {
        const fetchId = ++fetchIdRef.current;

        setIsProjectLoading(true);
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + `/CustomProject/projects/${projectKey}`
        )
            .then(async (result: Response) => {
                if (result)
                    if (fetchId === fetchIdRef.current) {
                        const vcoProject: VCOProject = await result.json();
                        setVcoProject(vcoProject);
                    }
            })
            .finally(() => {
                setIsProjectLoading(false);
            });

        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + `/CustomProject/batchKey`
        ).then(async (result: Response) => {
            if (result)
                if (fetchId === fetchIdRef.current) {
                    const batchKey = await result.text();
                    setBatchKey(parseInt(batchKey));
                }
        });

        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/User/organizations/' + organizationKey
        ).then(async (result: Response) => {
            const organization: Organization = await result.json();
            setOrganization(organization);
        });
    }, [api, instance, organizationKey, projectKey]);

    return (
        <div className="content-column-container">
            <Breadcrumb>
                <Breadcrumb.Item
                    onClick={() => {
                        navigate('/admin');
                    }}
                >
                    Configuration
                </Breadcrumb.Item>
                <ChevronRight size={24} className="breadcrumb-chevron" />
                <Breadcrumb.Item
                    onClick={() => {
                        navigate('/admin/projects');
                    }}
                >
                    Projects
                </Breadcrumb.Item>
                <ChevronRight size={24} className="breadcrumb-chevron" />
                <Breadcrumb.Item active>{vcoProject?.projectName}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content-header">
                <h1 className="content-title">
                    {vcoProject.organizationID}: {organization?.organizationName}
                </h1>
            </div>
            <div className="content-header">
                <p className="data-tools-description">
                    {vcoProject.projectID}: {vcoProject?.projectName}
                </p>
            </div>
            <ProjectEditForm isLoading={isProjectLoading} vcoProject={vcoProject} batchKey={batchKey} />
            <InventoryEditForm vcoProject={vcoProject} batchKey={batchKey} />
        </div>
    );
};

export default ProjectEditPage;
